// REACT
import React from "react"
// MUI
import { Typography } from "@material-ui/core"
// COMPONENTS
import Layout from "../components/layout"
import { PageTitle } from "../components/pageTitle"
import { Link } from "../components/link"

// COMPONENT FUNCTION
const Thanks = () => {
  // COMPONENT
  return (
    <Layout title="Thank You">
      <PageTitle title="THANK YOU" />

      <Typography paragraph>
        Your message was sent and we will respond to you shortly. Remember that
        you can always call us at 508-349-3840 if you need to.
      </Typography>

      <Link to="/">Home</Link>
    </Layout>
  )
}

export default Thanks
